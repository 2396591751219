.sponsor__placeholder
	height: $ui-padding * 1.75
	border-radius: $border-radius.soft
	border: 2px dashed $color.brand.blue
	margin-bottom: 1rem

	&::after
		display: block
		content: 'Your Logo'
		color: $color.brand.blue
		font-size: 115%
		line-height: $ui-padding * 1.75
		font-style: italic
		text-align: center
		letter-spacing: 1px
