.pagination
	font-size: 85%
	border-top: 2px dotted darken($color.brand.gray, 25%)
	margin-top: 3rem
	padding-top: 3rem

	@extend $clearfix

	.pagination__previous
	.pagination__next
		&::before
			position: absolute
			line-height: 1.5rem

	.pagination__previous
		float: left
		left: 1rem
		&::before
			content: '\276E'
			left: -1rem

	.pagination__next
		float: right
		right: 1rem
		&::before
			content: '\276F'
			right: -1rem
