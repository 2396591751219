/*
 * Container style
 */
.ps
	overflow: hidden !important
	overflow-anchor: none
	-ms-overflow-style: none
	touch-action: auto
	-ms-touch-action: auto

/*
 * Scrollbar rail styles
 */
.ps__rail-x
	display: none
	opacity: 0
	transition: background-color .2s linear, opacity .2s linear
	-webkit-transition: background-color .2s linear, opacity .2s linear
	height: 15px
	/* there must be 'bottom' or 'top' for ps__rail-x */
	bottom: 0px
	/* please don't change 'position' */
	position: absolute

.ps__rail-y
	display: none
	opacity: 0
	transition: background-color .2s linear, opacity .2s linear
	-webkit-transition: background-color .2s linear, opacity .2s linear
	width: 15px
	/* there must be 'right' or 'left' for ps__rail-y */
	right: 0
	/* please don't change 'position' */
	position: absolute

.ps--active-x > .ps__rail-x
.ps--active-y > .ps__rail-y
	display: block
	background-color: transparent

.ps:hover > .ps__rail-x
.ps:hover > .ps__rail-y
.ps--focus > .ps__rail-x
.ps--focus > .ps__rail-y
.ps--scrolling-x > .ps__rail-x
.ps--scrolling-y > .ps__rail-y
	opacity: 0.6

.ps__rail-x:hover
.ps__rail-y:hover
.ps__rail-x:focus
.ps__rail-y:focus
	background-color: transparent
	opacity: 0.9

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x
	background-color: lighten($color.brand.gray, 25%)
	border-radius: 6px
	transition: background-color .2s linear, height .2s ease-in-out
	-webkit-transition: background-color .2s linear, height .2s ease-in-out
	height: 6px
	/* there must be 'bottom' for ps__thumb-x */
	bottom: 2px
	/* please don't change 'position' */
	position: absolute

.ps__thumb-y
	background-color: lighten($color.brand.gray, 25%)
	border-radius: 6px
	transition: background-color .2s linear, width .2s ease-in-out
	-webkit-transition: background-color .2s linear, width .2s ease-in-out
	width: 6px
	/* there must be 'right' for ps__thumb-y */
	right: 2px
	/* please don't change 'position' */
	position: absolute

.ps__rail-x:hover > .ps__thumb-x
.ps__rail-x:focus > .ps__thumb-x
	background-color: desaturate(lighten($color.brand.gray, 33%), 25%)
	height: 11px

.ps__rail-y:hover > .ps__thumb-y
.ps__rail-y:focus > .ps__thumb-y
	background-color: desaturate(lighten($color.brand.gray, 33%), 25%)
	width: 11px

/* MS supports */
@supports (-ms-overflow-style: none)
	.ps
		overflow: auto !important


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
	.ps
		overflow: auto !important
