.floating-button
	align-content: center
	width: 4.5rem
	height: @width
	background: $color.brand.yellow
	background: linear-gradient(-30deg, lighten($color.brand.gray, 25%), darken($color.brand.gray, 20%))
	box-shadow: inset 0.15rem 0.15rem 0.5rem lighten($color.brand.gray, 25%), 0 1rem 2rem 0.5rem darken($color.brand.black, 25%)

	position: fixed
	z-index: 3
	border-radius: 50%
	bottom: @width * 0.3
	right: @width * 0.3

	&:hover
		cursor: pointer

	display: block
	@media (min-width: $breakpoint.medium)
		display: none

.floating-button__bar
	background: lighten($color.brand.gray, 80%)
	box-shadow: 0 2px 2px $color.brand.black

.floating-button__open-icon
	position: absolute
	width: 100%
	padding-top: 1.5rem
	.floating-button__bar
		width: 40%
		margin: 0 auto
		height: 4px
		margin-bottom: 4px
		@media (min-width: $breakpoint.small)
			height: 5px
			margin-bottom: 6px
		border-radius: 0.125rem
		opacity: 1
		for i in 1..3
			&:nth-child({i})
				transition: $timing.t2 all ((i / 20) - 0.1)s
		.sidebar-open &
			opacity: 0
			transform: translate(0.5rem)

.floating-button__close-icon
	position: absolute
	width: 100%
	padding-top: 2.5rem
	.floating-button__bar
		width: 0%
		height: 0.4rem
		margin: @height * -1 auto 0 // overlap without position absolute
		border-radius: 0.2rem
		opacity: 0
		transition: $timing.t3 all
		&:nth-child(1)
		&:nth-child(3)
			transform: rotate(45deg)
		&:nth-child(2)
			transform: rotate(-45deg)
	.sidebar-open &
		.floating-button__bar
			width: 40%
			opacity: 1

.floating-button__underlay
	position: fixed
	display: block
	z-index: 1
	top: 0
	right: 0
	bottom: 0
	left: 0
	background: darken($color.brand.black, 40%)
	transition: $timing.t3 opacity

	opacity: 0
	pointer-events: none

	@media (min-width: $breakpoint.medium)
		display: none

	.sidebar-open &
		opacity: 0.75
		pointer-events: auto
