.token.comment,
.token.prolog,
.token.doctype,
.token.cdata
	color: lighten($color.brand.gray, 30%)

.namespace
	opacity: .7

.token.property,
.token.tag,
.token.boolean,
.token.constant,
.token.symbol,
.token.deleted
	color: lighten($color.brand.yellow, 50%)

.token.char,
.token.inserted,
.token.keyword,
.token.attr-name
	color: lighten($color.brand.red, 40%)

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.punctuation
	color: lighten($color.brand.gray, 60%)

.token.atrule,
.token.attr-value,
.token.string,
.token.selector
	color: lighten($color.brand.blue, 50%)


.token.function
	color: white

.token.regex,
.token.important,
.token.variable,
.token.constant,
.token.boolean,
.token.builtin,
.token.number
	color: saturate(lighten($color.brand.purple, 50%), 75%)

.token.important,
.token.bold
	font-weight: bold

.token.italic
	font-style: italic

.token.entity
	cursor: help
