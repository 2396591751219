.github--container
	border-top: 1px solid saturate(darken($color.brand.gray, 35%), 100%)
	border-bottom: 1px solid saturate(darken($color.brand.gray, 35%), 100%)
	padding: $ui-padding * 0.33 1.3rem

.github
	.mobile-nav &
		width: 100%
		display: flex
		height: 3rem
		border: 1px solid saturate($color.brand.gray, 50%)
		& > *
			height: 3rem
			flex: 1 0 auto
			line-height: @height * 0.96
			text-align: center
	flex: 0 0 auto
	border: 1px solid lighten($color.brand.gray, 20%)
	border-radius: $border-radius.soft
	font-size: 0.875rem
	height: 2.25rem
	overflow: hidden
	background-color: transparent

	& > *
		display: inline-block
		height: 2.25rem
		line-height: @height * 0.96
		padding: 0 1rem
		font-weight: 700
		color: $color.text.heading

	@media (min-width: $breakpoint.medium)
		&:hover
			border-color: lighten($color.brand.gray, 20%)
			background-color: $color.brand.gray
			transition: background-color $timing.t2, border-color $timing.t2
			.github__star-count::after
				background-color: lighten($color.brand.gray, 20%)

.github__star-count
	font-feature-settings: tnum;
	font-variant-numeric: tabular-nums;
	&::before
		content: '\2605'
		color: $color.brand.yellow
		margin-right: 0.33rem
	&::after
		content: ''
		position: absolute
		display: block
		top: 0
		right: 0
		height: 100%
		width: 1px
		border-radius: 0
		background-color: saturate($color.brand.gray, 50%)
		transition: background-color $timing.t2

.github__icon
	svg
		height: 1.25rem
		vertical-align: middle
		top: -0.125rem
		margin-right: 0.25rem
// 	box-sizing: content-box
// 	position: relative
// 	top: 0.3rem
// 	height: 1.1rem
// 	margin: 0 -0.25rem
