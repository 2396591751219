.list__heading
	height: 2.5rem
	line-height: 2.5rem
	color: lighten($color.brand.gray, 30%)
	font-weight: normal
	text-transform: uppercase
	letter-spacing: 2px
	font-size: 0.75rem
	&:not(:first-child)
		margin-top: 2.5rem

.list__link
	display: block
	color: $color.text.heading
	height: 2.5rem
	line-height: @height
	.mobile-nav &
		border-top: 1px solid #1b273e
	.mobile-nav__primary &
		height: $ui-padding * 0.66
		line-height: @height * 0.98 // eyeballed
		font-size: 1.25em

	.list__heading + &
		border-top: 1px solid alpha($color.brand.gray, 50%)

	&::after
		transition: width $timing.t2
		position: absolute
		top: 0
		left: 0
		content: ''
		display: block
		background-color: lighten($color.brand.black, 20%)
		height: 100%
		width: 0.002%

	@media (min-width: $breakpoint.medium)
		&:hover
			background-color: alpha(lighten($color.brand.black, 20%), 12.5%)
			box-shadow: inset -1 * $ui-padding 0 $ui-padding * 1.5 -7rem #000
			&::after
				width: 0.55rem

	&.is-active
		color: $color.brand.yellow
		pointer-events: none
		cursor: default
		background: saturate(lighten($color.brand.black, 5%), 15%)
		.mobile-nav__secondary &
			background: saturate(lighten($color.brand.black, 10%), 15%)
		@media (min-width: $breakpoint.medium)
			box-shadow: inset -1 * $ui-padding 0 $ui-padding * 1.5 -4.5rem #000

		&::after
			position: absolute
			top: 0
			left: 0
			content: ''
			display: block
			background-color: currentColor
			transition: width $timing.t2
			width: 0.5rem
			height: 100%
