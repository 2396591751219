.demo
	display: flex
	justify-content: space-between

	// padding: $ui-padding * 0.4 $ui-padding * 0.5
	// @media (min-width: $breakpoint.large)
	// 	padding: $ui-padding * 0.8 $ui-padding

	/**
	 * The height of the demo ranges from
	 * approx. 2,400px to 6,480px based on
	 * browser height.
	 */
	height: 150rem
	for i in 1..3
		@media (min-height: (22.5 * i)em)
			height: (22.5 * i * 6)rem

.demo__column
	flex-grow: 0
	height: 100%

	width: 12.5%
	@media (min-width: $breakpoint.medium)
		width: 13.5%
	@media (min-width: $breakpoint.large)
		width: 14%

	display: flex
	flex-direction: column

.demo__bar
	flex-basis: 0
	flex-grow: 1

	&.demo__bar-medium
		flex-grow: 1.5

	&.demo__bar-large
		flex-grow: 2

.demo__bar__wrapper
	height: 100%

	padding-bottom: 30%
	@media (min-width: $breakpoint.medium)
		padding-bottom: 25%
	@media (min-width: $breakpoint.large)
		padding-bottom: 20%

	.demo__bar:last-child &
		padding-bottom: 0

.demo__bar__fill
	background: $color.brand.gray
	height: 100%

	border-radius: $border-radius.soft
	@media (min-width: $breakpoint.medium)
		border-radius: $border-radius.soft * 2

.demo__bar__fill-red
	background: $color.brand.red

.demo__bar__fill-orange
	background: $color.brand.orange

.demo__bar__fill-yellow
	background: $color.brand.yellow

.demo__bar__fill-green
	background: $color.brand.green

.demo__bar__fill-blue
	background: $color.brand.blue

.demo__bar__fill-purple
	background: $color.brand.purple
