.mobile-nav
	position: fixed
	z-index: 2
	overflow-y: auto
	width: 100%
	left: 0
	top: 0
	bottom: 0
	padding-top: $crown-height * 1.5

	background: $color.brand.black
	border-right: 1px solid $color.brand.gray
	box-shadow: 3px 0 1rem 1rem transparent
	transform: translate(-100%)

	-webkit-overflow-scrolling: touch
	-ms-overflow-style: none;

	// Typography hack, woot....
	& h3
		margin-top: 2rem
		&:first-child
			margin-top: 0rem

	@media (max-width: $breakpoint.medium)
		transition: $timing.t2 transform, $timing.t2 box-shadow

		.sidebar-open &
			transform: translate(0)
			box-shadow: 3px 0 3rem 1rem darken($color.brand.black, 40%)

.mobile-nav__crown
	position: fixed
	z-index: 3
	top: $ui-padding * -1
	width: 100%
	height: $ui-padding
	background: $color.brand.black
	border-bottom: 1px solid $color.brand.gray

	@media (max-width: $breakpoint.medium)
		display: block
		transition: $timing.t2 transform $timing.t2 * 0.8, $timing.t2 box-shadow $timing.t2 * 0.8

		.sidebar-open &
			transform: translateY($ui-padding)
			box-shadow: 3px 0 3rem 1rem darken($color.brand.black, 40%)

.mobile-nav__crown__logo
	display: flex
	align-items: center
	height: 100%

$content-box = ($sidebar-width * 0.9) - ($ui-padding * 0.33 * 2)

.mobile-nav__crown__logo__mark
	width: $content-box * 0.15

.mobile-nav__crown__logo__type
	margin-left: 1rem
	width: $content-box * 0.83 - 1rem // eyeballed down from 0.85

.mobile-nav__secondary
	padding: 2.5rem 0
	background: saturate(lighten($color.brand.black, 5%), 15%)
