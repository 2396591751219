/**
 * Clearfix
 * --------
 * The micro clearfix: http://cssmojo.com/latest_new_clearfix_so_far/
 */

.u-clearfix
$clearfix

    &:after
        content: ''
        display: table
        clear: both
