/**
 * Normalize
 * ---------
 */

html
    box-sizing: border-box
    background-color: $color.brand.black
    cursor: default
    height: 100%

html, body
    padding: 0
    margin: 0

body
    height: 100%
    width: 100%
    overflow-x: hidden

    @media (min-width: $breakpoint.medium)
        padding-top: $ui-padding

*, ::after, ::before
    position: relative
    margin: 0
    padding: 0
    box-sizing: inherit

input, button, textarea, select
    font: inherit

    -webkit-appearance: none
    -moz-appearance: none
    appearance: none

input[type='checkbox']
    -webkit-appearance: checkbox
    -moz-appearance: checkbox
    appearance: checkbox

input[type='radio']
    -webkit-appearance: radio
    -moz-appearance: radio
    appearance: radio

::-webkit-input-placeholder
    color: lighten($color.brand.gray, 40%)
    opacity: 1
    font-weight: normal

:-moz-placeholder
    color: lighten($color.brand.gray, 40%)
    opacity: 1
    font-weight: normal

::-moz-placeholder
    color: lighten($color.brand.gray, 40%)
    opacity: 1
    font-weight: normal

:-ms-input-placeholder
    color: lighten($color.brand.gray, 40%)
    opacity: 1
    font-weight: normal

button::-moz-focus-inner
    border: 0

ul, ol
    margin-left: 1.5rem
    margin-bottom: 1.5rem

li
    list-style-type: square

blockquote, p, table
    margin-bottom: 1.5rem

table
    border-collapse: collapse
