$skinWidth = 2px

.wiggle-cubes
	display: flex
	justify-content: space-around
	padding: 2.25rem 1.5rem 3rem
	border: 2px solid $color.brand.gray
	border-radius: $border-radius.soft

	pre + &
		margin-top: -1.25rem

.wiggle-cube__replay-button
	position: absolute
	bottom: -0.25rem
	right: -0.25rem

	background-color: $color.brand.black
	height: 2rem
	padding-right: 1.5rem
	padding-left: 1.875rem

	border: 2px solid $color.brand.gray
	border-top-left-radius: @height
	border-top-right-radius: $border-radius.sharp
	border-bottom-right-radius: $border-radius.soft
	border-bottom-left-radius: $border-radius.sharp

	text-align: center
	line-height: @height
	color: $color.text.heading
	font-weight: bold
	font-size: 80%
	text-transform: uppercase
	letter-spacing: 1px

	transition: 0.15s all ease-out

	&::before
		content: 'Replay'
	&:hover
		border-color: lighten($color.brand.yellow, 35%)
		background-color: $color.brand.yellow
		color: $color.brand.black
		cursor: pointer

.wiggle-cube
	width: 7%
	height: 7%
	background: $color.brand.blue
	border-radius: $border-radius.sharp
	animation: wiggle 1s ease-in-out infinite

for n in (1..10)
	.wiggle-cube:nth-child({n})
		animation-delay (n / -10)s

@keyframes wiggle
	0%
		transform rotate(5deg)
	50%
		transform rotateZ(-5deg)
	100%
		transform rotateZ(5deg)
