.pricing__background
    background: saturate(darken($color.brand.black, 30%), 20%)
    background-image: url('/img/bg-regal.png')
    border-top: 1px solid lighten($color.brand.black, 10%)
    border-bottom: @border-top
    padding: $ui-padding * 0.5 0

.pricing__content
    @extend .layout__wrapper
    @extend .layout__padding__horizontal
    display: flex
    flex-wrap: wrap

.pricing__badge
    display: flex
    flex-grow: 1
    flex-basis: 0
    width: 40%
    margin-right: $ui-padding * 0.25
    @media (max-width: $breakpoint.medium)
        flex-basis: auto
        width: 100%
        max-width: 33rem
        margin: -3.5rem auto -1em

    img
        width: 100%
        height: 100%

.pricing__matrix
    flex: 1 0 0
    @media (min-width: $breakpoint.medium)
        margin-left: $ui-padding * 0.25

    &.is-successful
        .pricing__success
            display: block
        .pricing__matrix__option
            display: none

.pricing__success
    display: none

.pricing__matrix__option
    display: flex
    flex-wrap: wrap
    align-items: baseline
    justify-content: space-between

    &:not(:last-child)
        margin-bottom: 1.5rem

    &:not(.clickable)
        padding: $ui-padding * 0.35 0
        @media (min-width: $breakpoint.small)
            padding: $ui-padding * 0.35 $ui-padding * 0.45

    &.clickable
        padding: $ui-padding * 0.35 $ui-padding * 0.45

colors = 'green' 'blue' 'purple'
for color in colors
    .pricing__matrix__option__{color}
        @extend .pricing__matrix__option
        themeColor = $color.brand[color]

        &::before
            content: ''
            display: block
            height: 0.5rem
            width: calc(100% + 4px)
            left: -2px
            margin-bottom: $ui-padding * 0.25
            border-radius: $border-radius.soft
            background: themeColor
            background: linear-gradient(lighten(themeColor, 20%), themeColor)
            transition: box-shadow $timing.t2
            box-shadow: 0 0 0 transparent, 0 0.2rem 0.3rem alpha(black, 0.2)

        &.clickable:hover::before
            if themeColor == $color.brand.purple
                box-shadow: 0 0 36px lighten(themeColor, 10%), 0 0.1rem 0.4rem alpha(black, 0.1)
            else
                box-shadow: 0 0 36px alpha(themeColor, 75%), 0 0.1rem 0.4rem alpha(black, 0.1)

        .pricing__matrix__option__description__emphasis
            font-weight: bold
            &::before
                content: ' '

        .pricing__matrix__option__description__emphasis
        .pricing__matrix__option__perk path
            if themeColor == $color.brand.green
                fill: desaturate(lighten(themeColor, 30%), 20%)
                color: desaturate(lighten(themeColor, 30%), 20%)
            else if themeColor == $color.brand.blue
                fill: desaturate(lighten(themeColor, 30%), 40%)
                color: desaturate(lighten(themeColor, 30%), 40%)
            else if themeColor == $color.brand.purple
                fill: saturate(lighten(themeColor, 30%), 15%)
                color: saturate(lighten(themeColor, 30%), 15%)

        .pricing__matrix__option__perk svg
            position: absolute
            border-radius: 50%
            width: 1.1rem
            height: @width
            font-size: 110%
            text-align: center
            left: 0
            line-height: 0.6

.pricing__matrix__option__heading
    width: 100%
    margin: 0
    margin-bottom: 0.2rem

.pricing__matrix__option__price
    border: none
    margin: 0
    padding: 0
    &::before
        content: 'USD'
        position: absolute
        top: -0.25rem
        font-size: 40%
        font-weight: normal
        left: -1.5rem

.pricing__matrix__option__description
    color: $color.text.regular
    font-weight: normal
    margin: 0

.pricing__matrix__option__perk
    width: 100%
    margin: 0
    padding: 0
    margin-top: 1rem
    padding-left: 1.5rem
    font-size: 90%
    line-height: 1.3
    color: $color.text.regular
    font-weight: normal

.pricing__submit
    margin-top: 1rem
    width: 100%

.pricing__notice
    margin-top: 1.1rem
    margin-bottom: 1rem
    border: 1px solid alpha($color.text.regular, 50%)
    background: alpha($color.text.regular, 5%)
    border-radius: $border-radius.sharp
    padding: 0.5rem
    text-align: center
    font-size: 85%
    letter-spacing: 1px

.pricing__common__question
    color: $color.text.heading
    font-style: italic
    line-height: 1.3
    &::before
        content: '“'
        position: absolute
        font-size: 150%
        top: -0.75rem
        left: -0.75rem
