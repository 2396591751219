table
    table-layout: fixed
    width: 100%
    border-top: none
    font-size: 80%
    @media (min-width: $breakpoint.medium)
        font-size: 90%

caption
    background-color: darken($color.brand.black, 50%)
    padding: 0.25rem
    text-align: left
    border-top-left-radius: $border-radius.soft
    border-top-right-radius: $border-radius.soft
    h3
        margin-bottom: 0

caption, th, td
    padding: 0.25rem 0.5rem
    @media (min-width: $breakpoint.medium)
        padding: 0.5rem 1rem

    .optional::after
        content: '(Optional)'
        margin-left: 0.5rem
        font-size: 80%
        text-transform: none
        color: lighten($color.brand.gray, 25%)
    code
        top: -0.15rem

td
    font-size: 100%
    @media (min-width: $breakpoint.small)
        font-size: 100%

th:first-child
td:first-child
    width: 35%
    @media (min-width: $breakpoint.medium)
        width: 30%
    @media (min-width: $breakpoint.large)
        width: 25%

tbody tr
    &:last-child
        td:first-child
            border-bottom-left-radius: $border-radius.soft
        td:last-child
            border-bottom-right-radius: $border-radius.soft
    &:nth-child(odd)
        background-color: lighten($color.brand.black, 3%)
