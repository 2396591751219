.infobox
	padding: 1.25rem 1.5rem 1.5rem
	border: 2px solid $color.brand.orange
	border-radius: $border-radius.soft
	background-color: darken($color.brand.black, 40%)
	margin-top: 3rem
	margin-bottom: 3rem

.infobox > *:last-child
	margin-bottom: 0 !important

.infobox::after
	position: absolute
	width: 1.8rem
	height: @width
	top: calc(50% - 0.9rem)
	left: -(@width / 2)
	content: url('/img/circle-star.svg')
	background-color: $color.brand.black
	border-radius: (@width / 2)
