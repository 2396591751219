.signature
    display: flex
    justify-content: center
    padding: 3rem 0
    border-top: 1px solid lighten($color.brand.black, 10%)
    color: lighten($color.brand.gray, 25%)
    font-weight: 500

    a
        color: white
        font-size: 110%

    .left
    .right
        flex: 1 0 auto
    .left
        text-align: right
    .right
        text-align: left

.love
    padding: 0 0.4rem
    animation: 1.5s heartThrob infinite

    svg
        height: 1.75rem

@keyframes heartThrob
    10%
        transform: scale(1.15)

    20%
        transform: scale(1)

    40%
        transform: scale(1.15)

    60%
        transform: scale(1)
