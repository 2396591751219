.search-bar
	flex: 1 1 auto
	@media (max-width: $breakpoint.medium)
		display: none

	.mobile-nav &
		margin: 2rem 0;

	.search-bar__input
		width: 100%
		margin-left: -2px
		padding: 0.3rem
		padding-left: 1rem
		padding-right: 2.25rem

		border-color: lighten($color.brand.gray, 20%)
		border-width: 1px
		border-style: solid
		border-radius: 2rem

		font-size: 0.9rem
		font-weight: bold
		color: lighten($color.brand.gray, 50%)
		background-color: $color.brand.black

		transition: background-color $timing.t2,
			color $timing.t2

		&:focus
			color: $color.text.heading
			background-color: $color.brand.gray
			outline: none

	.search-bar__icon
		position: absolute
		width: 1rem
		height: @width
		right: 0.85rem
		top: 0.6rem
		fill: white
		z-index: 2
