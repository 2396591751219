.input
    label
        display: block
        color: white
        text-transform: uppercase
        font-weight: bold
        font-size: 0.8rem
        letter-spacing: 1px
        height: 1.5rem
        line-height: 1.5rem
        top: 0
        left: 2px
        transition: top $timing.t2

    .input__wrapper
        margin-bottom: 1rem
        &::after
            content: ''
            display: block
            position: absolute
            top: -6px
            left: -6px
            right: -6px
            bottom: -6px
            border-radius: $border-radius.soft
            border: 1px solid $color.brand.yellow
            transition: $timing.t1 opacity
            opacity: 0
            pointer-events: none

    &.focused
        label
            top: -4px
            color: $color.brand.yellow
        .input__wrapper::after
            opacity: 1

    .StripeElement
    input
        width: calc(100% - 2rem - 2px)
        border: 1px solid $color.brand.gray
        background: transparent
        border-radius: $border-radius.sharp
        padding: 0.7rem 1rem
        box-sizing: content-box
        color: lighten($color.brand.gray, 35%)
        font-weight: bold
        transition: all $timing.t2
        height: 1.5rem

        &:focus
        &.StripeElement--focus
            border-color: lighten($color.brand.gray, 25%)
            color: white
            outline: none

        &.invalid
        &.StripeElement--invalid
            border-color: $color.error.regular

    #stripe-card
        padding-top: 1rem
        padding-bottom: 0.4rem

    .input__error
        color: $color.error.regular
        font-size: 90%
        margin: 0
        padding: 0
