.crown
	position: fixed
	z-index: 3
	top: 0
	width: 100%
	border-bottom: 1px solid darken($color.brand.gray, 25%)
	background-color: $color.brand.black
	box-shadow: 0 1rem 5rem -0.5rem #000
	height: $crown-height

	display: none
	@media (min-width: $breakpoint.medium)
		display: block

.crown__logo
	float: left
	display: flex
	align-items: center
	height: 100%

.crown__logo__mark
	width: 18%

.crown__logo__type
	margin-left: 1rem
	width: calc(82% - 1rem)

.crown__menu
	display: flex
	align-items: center
	justify-content: flex-end
	height: 100%

	& > *:not(:last-child)
		margin-right: 1.5rem

.crown__menu__link
	color: $color.text.heading
	&.is-active
		color: $color.brand.yellow

.crown
	.github
		display: none
		@media (min-width: 52rem) // eye balled this value
			display: block
		@media (min-width: $breakpoint.large)
			display: none
		@media (min-width: 64rem) // eye balled this value
			display: block
