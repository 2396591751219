.layout__wrapper
	margin: 0 auto
	max-width: $site-width

.layout__two-column__main
	height: 100%
	margin-left: 0
	@media (min-width: $breakpoint.medium)
		margin-left: $sidebar-width * 0.9
	@media (min-width: $breakpoint.large)
		margin-left: $sidebar-width

.layout__two-column__aside
	float: left
	height: 100%
	width: $sidebar-width * 0.9
	@media (min-width: $breakpoint.large)
		width: $sidebar-width

.layout__two-column__fixed
	position: fixed
	top: $crown-height
	bottom: 0
	overflow-y: scroll
	-webkit-overflow-scrolling: touch
	@media (min-width: $breakpoint.medium)
		.js &
			overflow-y: hidden
		.no-js &
			overflow-y: auto

	width: $sidebar-width * 0.9
	@media (min-width: $breakpoint.large)
		width: $sidebar-width
	box-shadow: inset -9rem 0 9rem -9rem #000
	border-right: 1px solid darken($color.brand.gray, 25%)

	display: none
	@media (min-width: $breakpoint.medium)
		display: block

.layout__padding__horizontal
	padding-left: $ui-padding * 0.33
	padding-right: $ui-padding * 0.33
	@media (min-width: $breakpoint.medium)
		padding-left: $ui-padding * 0.66
		padding-right: $ui-padding * 0.66
	@media (min-width: $breakpoint.large)
		padding-left: $ui-padding
		padding-right: $ui-padding

.layout__padding__vertical
	padding-top: $ui-padding * 0.33
	@media (min-width: $breakpoint.medium)
		padding-top: $ui-padding * 0.6125
	@media (min-width: $breakpoint.large)
		padding-top: $ui-padding * 0.8

	padding-bottom: $ui-padding * 1.25 // to not cover mobile nav toggle
	@media (min-width: $breakpoint.medium)
		padding-bottom: $ui-padding

@media (min-width: $breakpoint.medium)
	::-webkit-scrollbar {
		width: 1rem
		height: 1rem
		background-color: saturate(darken($color.brand.gray, 40%), 75%)
		&:horizontal {
			border-radius: 0 0 $border-radius.soft $border-radius.soft
		}
	}

	::-webkit-scrollbar-thumb {
		border-radius: $border-radius.soft
		background-color: $color.brand.gray
		box-shadow: inset 0 0 0 1px lighten($color.brand.gray, 12%)
		border: 0.2rem solid saturate(darken($color.brand.gray, 40%), 75%)
		&:hover {
			background-color: lighten($color.brand.gray, 10%)
		}
	}
