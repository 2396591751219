.button
	@extend .clickable
	padding: 0.5rem 1rem
	text-align: center
	font-size: 85%
	font-weight: bold
	text-transform: uppercase
	letter-spacing: 2px
	color: white

.button__spinner
	display: none
&.is-active
	.button__spinner
		display: block
	.button__content
		display: none

colors = 'green' 'blue' 'purple'
for color in colors
	.button__{color}
		@extend .clickable__{color}
		themeColor = $color.brand[color]
		text-shadow: 0 1px darken(themeColor, 50%), 0 2px darken(themeColor, 50%)

#purchase .button svg
	height: 1rem
	width: 1rem
	top: 0.1rem
	path
		fill: white
