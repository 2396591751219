.clickable
    display: block
    color: $color.text.regular
    font-weight: normal
    border-radius: $border-radius.sharp
    top: 0
    transition: top $timing.t1, box-shadow $timing.t1
    &.is-active
        pointer-events: none

    &:hover:not(.is-active)
        cursor: pointer

    &::after
        content: ''
        display: block
        position: absolute
        top: -6px
        left: -6px
        right: -6px
        bottom: calc(-6px - 0.33rem)
        border-radius: $border-radius.soft
        border: 1px solid $color.brand.yellow
        transition: $timing.t1 opacity, $timing.t1 bottom
        opacity: 0

    &:active
    &:focus
        outline: none
        &::after
            opacity: 1

colors = 'black' 'green' 'blue' 'purple'
for color in colors
    .clickable__{color}
        themeColor = $color.brand[color]
        background: themeColor
        background: linear-gradient(themeColor, darken(themeColor, 30%))
        border: 1px solid lighten(themeColor, 10%)
        box-shadow: 0 0.33rem 0 darken(themeColor, 40%), 0 0.5rem 0.2rem -0.1rem black
        text-shadow: 0 1px darken(themeColor, 50%), 0 2px darken(themeColor, 50%)

        &:hover:not(.is-active)
            background: lighten(themeColor, 5%)
            background: linear-gradient(lighten(themeColor, 5%), darken(themeColor, 25%))
            box-shadow: 0 0.33rem 0 darken(themeColor, 45%), 0 0.5rem 0.2rem -0.1rem black

        // &.is-active
        &:active
            top: 0.33rem
            box-shadow: 0 0 0 darken(themeColor, 40%), 0 0.15rem 0.2rem -0.1rem black

        // &.is-active::after
        &:active::after
            bottom: -6px
