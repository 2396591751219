.flex-container
    display: flex
    flex-direction: column
    height: 100%

.flex-body
    flex: 1 1 0

.flex-footer
    flex-shrink: 0
    @media (max-width: $breakpoint.medium)
        padding-bottom: 4rem
