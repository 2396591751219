/**
 * Typography
 * ----------
 */

html
    color: $color.text.regular
    font-size: 14px
    line-height: 1.5em
    font-family: $font-family.normal

    @media (min-width: $breakpoint.small)
        font-size: 16px

    @media (min-width: $breakpoint.medium)
        font-size: 14px

    @media (min-width: $breakpoint.large)
        font-size: 16px

h1
$heading-1
.heading-1
    display: block
    color: $color.text.heading
    font-weight: 700
    line-height: 2.4rem

    margin-bottom: 1.5rem
    font-size: 2em
    @media (min-width: $breakpoint.medium)
        margin-bottom: 3rem
        font-size: 3em

    &.hero
        text-align: center
        margin: 0

        padding: $ui-padding * 0.33 0
        @media (min-width: $breakpoint.medium)
            padding: $ui-padding * 0.6125 0
        @media (min-width: $breakpoint.large)
            padding: $ui-padding * 0.8 0

h2
$heading-2
.heading-2
    display: block
    margin-bottom: 1.5rem
    color: $color.text.heading
    font-size: 1.25em
    @media (min-width: $breakpoint.medium)
        font-size: 1.75em
    font-weight: 300
    line-height: 1.5rem

    margin-top: 3rem
    border-top: 2px dotted darken($color.brand.gray, 25%)
    padding-top: 3rem
    &:first-child
    h1 + &
        border-top: none
        margin-top: 0
        padding-top: 0

h3
$heading-3
.heading-3
    display: block
    margin-bottom: 0.75rem

    color: $color.text.heading
    font-size: 0.875rem
    font-weight: 600
    line-height: 1.5rem
    text-transform: uppercase
    letter-spacing: 0.1rem

    margin-top: 3rem
    &:first-child
        margin-top: 0

hr
    margin-top: calc(1.5rem - 1px)
    margin-bottom: calc(1.5rem - 1px)
    border: 0
    border-top: 1px solid 2px dotted darken($color.brand.gray, 25%)

a
    color: $color.brand.yellow
    text-decoration: none
    cursor: pointer
    font-weight: 700
    display: inline-block

    @media (min-width: $breakpoint.medium)
        &:not(.no-style):not(.button)
            &::after
                position: absolute
                bottom: 0
                content: ''
                display: block
                background-color: currentColor
                transition: width $timing.t2
                width: 0.001%
                border-radius: 2px
                height: 2px
                left: 0

            &.is-active
            &:hover
            &:active
                &::after
                    width: 100%


blockquote
    padding-left: 2.5rem
    padding-right: 2.5rem
    font-style: italic
    &::before
        position: absolute
        top: 0
        left: 1rem
        content: '“'
        font-size: 2.5rem

code
    font-family: $font-family.monospace
    display: inline-block
    background-color: saturate(darken($color.brand.gray, 25%), 50%)
    border-radius: $border-radius.sharp
    padding: 0.25em
    color: lighten($color.brand.gray, 60%)
    font-size: 90%
    line-height: 1
    vertical-align: bottom

    h2 > &
        top: .5rem

    a &
        color: $color.brand.yellow
        background-color: alpha($color.brand.yellow, 25%)
        &::after
            display: none

    pre &
        display: block
        overflow-x: scroll
        -webkit-overflow-scrolling: touch
        line-height: inherit
        margin-bottom: 1.5rem
        border-radius: $border-radius.soft

        font-size: 80%
        padding: 1rem
        @media (min-width: $breakpoint.large)
            font-size: 90%
            padding: 1.5rem
    @media (min-width: $breakpoint.medium)
        .js pre &
            overflow-x: hidden
        .no-js pre &
            overflow-x: auto

pre::after
    display: block
    content: attr(data-lang)
    position: absolute
    top: 0
    right: 0.5rem
    letter-spacing: 1px
    font-family: $font-family.normal
    font-weight: 700
    color: lighten($color.brand.gray, 35%)
    text-transform: uppercase
    font-size: 70%
    @media (min-width: $breakpoint.medium)
        font-size: 80%

// ::-moz-selection
//     background-color: $color.brand.yellow
//     color: $color.brand.black

// ::selection
//     background-color: $color.brand.yellow
//     color: $color.brand.black
