.metabox
	padding: 1.25rem 1.5rem 1.5rem
	border: 2px solid $color.brand.gray
	border-radius: $border-radius.soft
	background-color: darken($color.brand.black, 40%)
	margin-top: 1rem
	margin-bottom: 2rem
	color: lighten($color.brand.gray, 50%)

.metabox[data-heading]::before
	content: attr(data-heading)
	display: block
	font-size: 90%
	opacity: 0.7
	margin-bottom: 0.75rem
